









































video {
  width: 100%;
}
