.my_footer {
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.my_footer .outer_pre_box {
  min-width: 66px;
}
.my_footer .side_pre_box {
  padding: 5px 11px 5px 6px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.my_footer .side_nex_box {
  padding: 5px 6px 5px 11px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.anticon-luyin2 {
  font-size: 50px;
}
