
























































































.my_footer {
  height: 70px;
  line-height: 70rpx;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .reset {
    width: 50px;
    height: 50px;
    background-color: lightgray;
    text-align: center;
    line-height: 50px;
    border-radius: 50%;
  }
  .prev_ques {
    width: 60px;
    height: 30px;
    text-align: center;

    .prev_text {
      line-height: 30px;
      font-size: 0.77rem;
      width: 60px;
      height: 30px;
      border-top-right-radius: 25px;
      border-bottom-right-radius: 25px;
      color: white;
    }
  }
  .next_ques {
    width: 60px;
    height: 30px;
    text-align: center;
    .next_text {
      line-height: 30px;
      font-size: 0.77em;
      width: 60px;
      height: 30px;
      border-top-left-radius: 25px;
      border-bottom-left-radius: 25px;
      color: white;
    }
  }
  .side_text {
    vertical-align: top;
  }
}
