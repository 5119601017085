.water_container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
.water_container .water1 {
  -webkit-animation: wateranimate 6s 6s ease-out infinite;
  animation: wateranimate 6s 6s ease-out infinite;
}
.water_container .water2 {
  -webkit-animation: wateranimate 6s 4s ease-out infinite;
  animation: wateranimate 6s 4s ease-out infinite;
}
.water_container .water3 {
  -webkit-animation: wateranimate 6s 2s ease-out infinite;
  animation: wateranimate 6s 2s ease-out infinite;
}
.water_container .water4 {
  -webkit-animation: wateranimate 6s 0s ease-out infinite;
  animation: wateranimate 6s 0s ease-out infinite;
}
.water_container .water1,
.water_container .water2,
.water_container .water3,
.water_container .water4 {
  padding: 50%;
  position: absolute;
  left: 0%;
  top: 0%;
  border: 1px solid white;
  box-shadow: 0 0 30px 10px #ffffff inset;
  border-radius: 100%;
  z-index: 1;
  opacity: 0;
}
@-webkit-keyframes wateranimate {
  0% {
    -webkit-transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1.5);
    opacity: 0;
  }
}
@keyframes wateranimate {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }
}
