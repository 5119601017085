.outer_content_page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.outer_content_page .game_name {
  font-size: 20px;
  font-weight: bold;
}
.outer_content_page .game_title {
  font-size: 30px;
  font-weight: bold;
}
.outer_content_page .card_img img {
  width: 350px;
}
.footer {
  height: 100px;
  line-height: 100px;
}
.footer .anticon-yyashow {
  font-size: 60px;
}
pre {
  white-space: pre-line;
}
.outer_content_page .inner_content {
  padding: 30px;
  flex: 2;
  height: 100%;
  position: relative;
}
.outer_content_page .inner_content .content_container {
  position: absolute;
  padding: 30px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.outer_content_page .inner_content .content_container .img_outer .inner_img {
  height: 300px;
}
.outer_content_page .inner_content .translate_img {
  position: absolute;
  font-size: 30px;
  right: 40px;
  bottom: 40px;
}
.outer_content_page .inner_content .score_container_bottom {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
}
