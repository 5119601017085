.outer_content_page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.outer_content_page .game_name {
  font-size: 20px;
  font-weight: bold;
}
.outer_content_page .game_title {
  font-size: 30px;
  font-weight: bold;
}
.outer_content_page .card_img img {
  width: 350px;
}
.footer {
  height: 100px;
  line-height: 100px;
}
.footer .anticon-yyashow {
  font-size: 60px;
}
pre {
  white-space: pre-line;
}
.outer_content_page .inner_content {
  padding: 30px;
  flex: 2;
  height: 100%;
  overflow-y: scroll;
}
.outer_content_page .video_box {
  text-align: center;
  width: 500px;
  margin: 0 auto;
}
.outer_content_page .rich_text_container {
  height: 300px;
  width: 500px;
  margin: 0 auto;
  overflow: scroll;
}
