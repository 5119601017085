















































































































































































































































































































































































@import '../index.less';
.outer_content_page {
  .inner_content {
    padding: 30px;
    flex: 2;
    height: 100%;
    position: relative;
    .content_container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow-y: scroll;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      padding: 0 30px;
      .img_outer {
        .inner_img {
          height: 300px;
        }
      }
    }

    .translate_img {
      position: absolute;
      font-size: 30px;
      right: 40px;
      bottom: 40px;
    }
    .score_container_bottom {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 100%;
    }
  }
}

.report_box {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999;
}
