.anticon-luyin2 {
  font-size: 60px;
}
.outer_box {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* 最外层的盒子 */
}
.outer_box .box {
  width: 56px;
  height: 56px;
  margin: 0 auto;
  position: relative;
  border-radius: 50%;
  float: left;
  /* 
      左右两边用于 隐藏 旋转的div的盒子 
      通过overflow来隐藏内部div旋转出去的部分
    */
  /* 
      这是需要旋转的div(没有被mask遮盖展示出来的部分作为倒计时的线条)
      为了方便理解，下面用deeppink和cyan分别设置了左右两边div的颜色
    */
  /*
      1.设置圆角，圆角大小为高度的一半
      2.这只旋转的中心店，这是左边圆，中心点设置到右边中心点，右边圆则设置到左边中心点
    */
}
.outer_box .box .left_box,
.outer_box .box .right_box {
  position: absolute;
  top: -2px;
  width: 30px;
  height: 60px;
  overflow: hidden;
  z-index: 1;
  font-size: 0;
}
.outer_box .box .left_box {
  left: 0px;
}
.outer_box .box .right_box {
  right: 0px;
}
.outer_box .box .left_item,
.outer_box .box .right_item {
  width: 30px;
  height: 60px;
}
.outer_box .box .left_item {
  border-top-left-radius: 60px;
  border-bottom-left-radius: 60px;
  -webkit-transform-origin: right center;
  transform-origin: right center;
  background-color: #fff;
}
.outer_box .box .right_item {
  border-top-right-radius: 60px;
  border-bottom-right-radius: 60px;
  -webkit-transform-origin: left center;
  transform-origin: left center;
  background-color: #fff;
}
.outer_box .box .mask {
  /* 遮住div多余的部分，呈现出线条的效果 */
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  z-index: 2;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.outer_box .box .mask .anticon-yyashow {
  font-size: 50px;
  top: 0px;
  bottom: 0;
  right: 0;
  left: 1px;
  margin: 0 auto;
  line-height: 1;
}
.outer_box .right_text {
  font-size: 40px;
  margin-left: 20px;
}
