














































































































#main {
  margin: 0 auto;
}
.report_container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  .delete_icon {
    font-size: 35px;
    color: white;
    margin: 0 auto;
    text-align: center;
    margin-top: 20px;
    font-weight: bold;
  }
  .evaluate_container {
    width: 70%;
    margin: 0 auto;
    height: 80%;
    top: 10%;
    .title_static {
      padding: 20px;
      background: white;
      border-radius: 20px;
      flex: 2;
      overflow-y: scroll;
      height: 100%;
      .title_box {
        height: 70px;
        line-height: 70px;
        display: flex;
        justify-content: space-around;
        .flex_title {
          display: flex;
          align-items: center;
          width: 100%;
          .line {
            width: 30%;
            height: 2px;
          }
          .text {
            text-align: center;
            width: 40%;
          }
        }
      }
      .main_box {
        flex: 2;
      }
      .empty_box {
        height: 600px;
      }
      .canvas {
        width: 600px;
        height: 600px;
        margin: 0 auto;
      }
    }
  }
}
