.score_container {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 50px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-around;
  z-index: 9;
}
.score_container .play_audio_box {
  position: relative;
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.score_container .play_audio_box .listen_img {
  width: 30px;
  height: 30px;
}
.score_container .his_score {
  color: #ffffff;
  font-size: 0;
  position: relative;
  width: 230px;
  text-align: center;
}
.score_container .his_score .triangle_container {
  left: 0;
  color: white;
  width: 100%;
  top: -60px;
  height: 33px;
  position: absolute;
  white-space: nowrap;
  padding-bottom: 20px;
  z-index: 10;
}
.score_container .his_score .triangle_container .text_box {
  padding: 10px 15px;
  font-size: 18px;
  border-radius: 10px;
}
.score_container .his_score .triangle_container .triangle_box {
  z-index: -10;
  width: 20px;
  height: 20px;
  transform: translateX(-50%) rotate(45deg);
  left: 50%;
  border-top-color: transparent;
  border-left-color: transparent;
  position: absolute;
  bottom: -10px;
}
.score_container .his_score .en_score {
  font-size: 30px;
  line-height: 30px;
}
.score_container .his_score .score_text {
  color: #ffffff;
  font-size: 30px;
}
.score_container .anticon-yyashow.score_translate_img {
  font-size: 30px;
  line-height: 30px;
}
